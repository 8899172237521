.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

body{
  background-color: #fff !important;
}

.card-header{
  background-color: #ababab;
}

.card{
  border-color: #ababab !important;
}

.bg-table-head-primary{  
  color: #fff !important;
}

.bg-table-head-primary > tr th {
  background-color: #283d62 !important;
}

.lineThrough {
  text-decoration: line-through;
}

.list-group-item:hover{
  cursor: pointer !important;
  background-color: #eee !important;
  font-weight: bold !important;
}

.table-th-style-color {
  background-color: #518de5 !important;
  color: #fff !important;
}

.table-th-style{
  font-size: 12px !important;
}

.table-td-style{
  font-size: 11px !important;
}

.table-ft-style{
  font-size: 14px !important;
}

.white-card-border-color {
  border-color: #fff !important;
}

.bg-white-color {
  background-color: #fff !important;
}

.react-datetime-picker {
  width: 100% !important;
  height: 38px;
}

.swal2-styled.swal2-confirm{
  background-color: #0d6efd !important;
}

/******Customize*****/

.cus-nav {
  background-color: #023b6d !important;
}

.txt-white {
  color: #fff !important;
}

.tr-hover {
  cursor: pointer;
}

.tr-hover > td:hover {
  background-color: #eee !important;
  font-weight: bold !important;
  color: #000 !important;
}

.row-following {
  border-bottom: 1px solid #d6d6d6 !important;
}

.select-styling {
  border: 0 !important;
  padding: 0 !important;
}

.switch-table > input {
  width: 50px !important;
  height: 25px !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
